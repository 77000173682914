<template>
  <div class="container-fluid mt-6">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div class="row">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_VEHICLES)"
          :to="{ name: 'List Vehicles' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-regular fa-2x fa-car"></i>
            </span>
            <small>{{ $t("COMMON.VEHICLES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_REQUESTS)"
          :to="{ name: 'List Requests' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-regular fa-2x fa-arrows-retweet"></i>
            </span>
            <small>{{ $t("COMMON.REQUESTS") }}</small>
          </a>
        </router-link>

        <router-link
          :to="{ name: 'Get Vehicle Estimate' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_VEHICLE_ESTIMATIONS)
          "
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-regular fa-2x fa-clipboard-list-check"></i>
            </span>
            <small>{{ $t("COMMON.VEHICLE_ESTIMATIONS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_REQUEST_LOGS)"
          :to="{ name: 'List Request Logs' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-solid fa-2x fa-code"></i>
            </span>
            <small>{{ $t("COMMON.REQUEST_LOGS") }}</small>
          </a>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
